import { CheckOutlined, DownOutlined, UpOutlined, EditOutlined, FundProjectionScreenOutlined } from '@ant-design/icons';
import { Badge, Col, Dropdown, Input, Menu, Row, Space, Switch, Tooltip, Upload } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import filterIcon from '../../../assets/icons/filter.svg';
import searchIcon from '../../../assets/icons/search.svg';
import sortIcon from '../../../assets/icons/sort.svg';
import { Button, TabButton } from '../Button/Button';
import FilterDrawer from './FilterDrawer';

export const downloadIcon = (
  <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.5 17.75L17.4395 16.6895L15.5 18.629V12.5H14V18.629L12.0605 16.6895L11 17.75L14.75 21.5L18.5 17.75Z" />
    <path d="M9.5 20.0003H2V2.00026H8V6.50026C8.00119 6.89772 8.1596 7.27856 8.44065 7.5596C8.7217 7.84065 9.10254 7.99907 9.5 8.00026H14V10.2503H15.5V6.50026C15.5026 6.40169 15.4839 6.30372 15.4451 6.21309C15.4062 6.12246 15.3482 6.04132 15.275 5.97526L10.025 0.725255C9.95897 0.652013 9.87784 0.593968 9.7872 0.555123C9.69656 0.516278 9.59857 0.497558 9.5 0.500255H2C1.60254 0.501442 1.2217 0.659859 0.940651 0.940906C0.659604 1.22195 0.501187 1.6028 0.5 2.00026V20.0003C0.501187 20.3977 0.659604 20.7786 0.940651 21.0596C1.2217 21.3407 1.60254 21.4991 2 21.5003H9.5V20.0003ZM9.5 2.30026L13.7 6.50026H9.5V2.30026Z" />
  </svg>
);

export const dropdownArrow = (
  <svg width="10" height="10" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 6.00078L0 1.00078L0.7 0.300781L5 4.60078L9.3 0.300781L10 1.00078L5 6.00078Z" />
  </svg>
);

interface CTA {
  icon?: Array<IconCTA>;
  text?: Array<TextCTA>;
  textGroup?: Array<TextGroupCTA>;
  button?: Array<ButtonCTA>;
  buttonGroup?: Array<ButtonGroupCTA>;
  toggle?: Array<ToggleCTA>;
}

interface TextGroupCTA {
  text: string;
  icon?: any;
  disabled?: boolean;
  list: Array<TextCTA>;
  tooltip?: string;
}

interface ButtonGroupCTA {
  text: string;
  icon?: any;
  type?: string;
  size?: string;
  editPermission?: boolean;
  list: Array<ButtonCTA>;
  disabled?: boolean;
  tooltip?: string;
}

interface IconCTA {
  icon: any;
  text: string;
  onClick: () => void;
  tooltip?: string;
}

interface TextCTA {
  text: string;
  icon?: any;
  disabled?: boolean;
  props?: any;
  to?: string;
  href?: string;
  onClick?: () => void;
  tooltip?: string;
}

interface ButtonCTA {
  text: string;
  icon?: any;
  disabled?: boolean;
  loading?: boolean;
  editPermission?: boolean;
  props?: any;
  to?: string;
  href?: string;
  type?: string;
  size?: string;
  onClick?: () => void;
  tooltip?: string;
}

interface ToggleCTA {
  text?: string;
  option1: string;
  option2: string;
  disabled?: boolean;
  loading?: boolean;
  onClick: (val: any) => void;
  active: boolean;
  tooltip?: string;
}

interface Tabs {
  list: Array<Tab>;
  activeTab: string;
  tabChangeHandler: (key: string) => void;
}

interface Tab {
  key: string;
  text: string;
  notification: boolean;
  tooltip?: string;
}

interface Search {
  placeholder: string;
  key: string;
  resetSearch?: () => void;
  disabled?: boolean;
  size?: 'small' | 'middle' | 'large';
}

interface Filter {
  list: Array<string>;
  notification: boolean;
  products?: any[];
  providers?: any[];
  policyManagers?: any[];
  claimManagers?: any[];
  btnLoading: boolean;
  loading: boolean;
  onClick?: () => void;
  visible: boolean;
  claimStatus?: any[];
  claimTypes?: any[];
  tpas?: any[];
  policies?: any[];
}

interface Sort {
  notification: boolean;
  onClick?: () => void;
  options: Array<SortOptions>;
}

interface SortOptions {
  key: string;
  title: string;
  sort: Array<SortSubOptions>;
}

interface SortSubOptions {
  key: string;
  type: 'asc' | 'desc';
}

interface Summary {
  totalCount?: number;
  checklistCount?: number;
}

interface Props {
  tabs?: Tabs;
  title?: string | JSX.Element | undefined;
  search?: Search;
  cta?: CTA;
  filter?: Filter;
  sort?: Sort;
  params?: any;
  getParams?: (params: any) => void;
  resetParams?: () => void;
  summary?: Summary;
  disableSearch?: boolean;
  customComponent?: JSX.Element;
  customComponentTwo?: JSX.Element;
  tabTop?: boolean;
}

const TableCTAComponent: React.FC<Props> = ({
  getParams,
  resetParams,
  params,
  cta,
  search,
  tabs,
  title,
  filter,
  sort,
  summary,
  customComponent,
  customComponentTwo,
  tabTop,
}) => {
  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined),
    [filterVisible, setFilterVisible] = useState<boolean>(false),
    iconMap = new Map([
      ['download', downloadIcon],
      ['edit', <EditOutlined />],
      ['analytics', <FundProjectionScreenOutlined style={{ fontSize: '22px' }} />],
    ]),
    sortingHandler = (key: string) => {
      if (params && getParams) {
        if (params?.ordering === key) {
          getParams({ ordering: '' });
        } else {
          getParams({ ordering: key });
        }
      }
    },
    sortingMenu = (
      <Menu>
        {sort?.options.map((item: any) => {
          return (
            <SubMenu key={item?.key} title={item?.title}>
              {item?.sort?.map((data: any) => (
                <Menu.Item key={data?.key} onClick={() => sortingHandler(data?.key)}>
                  {data?.type === 'asc' ? <UpOutlined /> : <DownOutlined />} {item?.title}{' '}
                  {params?.ordering === data?.key ? <CheckOutlined /> : null}
                </Menu.Item>
              ))}
            </SubMenu>
          );
        })}
      </Menu>
    ),
    groupOverlay = (list: any) => {
      return (
        <div className="overflow-menu">
          {list?.map((item: any, index: any) => {
            return <React.Fragment key={index}>{CTAOptions(item, textCTA)}</React.Fragment>;
          })}
        </div>
      );
    },
    resetSearch = () => {
      setSearchTerm(undefined);
    },
    coreCTA = (item: any) => {
      if (item?.icon) {
        return (
          <Row align="middle">
            <Col className="cta icon cta-text-gap-right">{iconMap.get(item?.icon)}</Col>
            <Col>{item?.text}</Col>
          </Row>
        );
      } else {
        return item?.text;
      }
    },
    textCTA = (item: any) => {
      return (
        <div
          className={`cta ${item?.disabled ? 'cta-disabled cta-transparent' : ''} item`}
          onClick={() => {
            if (!item?.disabled && item?.onClick) {
              item?.onClick();
            }
          }}
        >
          {coreCTA(item)}
        </div>
      );
    },
    buttonCTA = (item: any) => {
      return (
        <Button
          type={item?.type || 'primary'}
          disabled={item?.disabled}
          onClick={item?.onClick}
          loading={item?.loading}
          editPermission={item?.editPermission}
          size={item?.size || 'small'}
          tooltip={item?.tooltip}
        >
          {coreCTA(item)}
        </Button>
      );
    },
    CTAOptions = (item: any, type: any) => {
      return (
        <React.Fragment>
          {item?.to && <NavLink to={item?.to}>{type(item)}</NavLink>}
          {item?.props && <Upload {...item?.props}>{type(item)}</Upload>}
          {item?.href && (
            <a href={item?.href} target="_blank" rel="noopener noreferrer">
              {type(item)}
            </a>
          )}
          {!item?.to && !item?.props && !item?.href && <div>{type(item)}</div>}
        </React.Fragment>
      );
    };

  useEffect(() => {
    let timer = setTimeout(() => {
      if (search && getParams && searchTerm) {
        getParams({ [search.key]: searchTerm, offset: 0 });
      }
    }, 500);

    if (search && getParams && searchTerm === '') {
      getParams({ [search.key]: null, offset: 0 });
    }

    return () => clearTimeout(timer);
  }, [searchTerm]);

  useEffect(() => {
    resetSearch();
  }, [params?.object_id, params?.type]);

  return (
    <React.Fragment>
      {filter && filter.visible && (
        <FilterDrawer
          filters={filter.list}
          visible={filterVisible}
          params={params}
          getParams={getParams}
          onClose={() => {
            setFilterVisible(false);
          }}
          resetParams={resetParams}
          products={filter?.products}
          providers={filter?.providers}
          claimStatus={filter?.claimStatus}
          claimTypes={filter?.claimTypes}
          tpas={filter?.tpas}
          policies={filter?.policies}
          policy_managers={filter?.policyManagers}
          claim_managers={filter?.claimManagers}
          btnLoading={filter.btnLoading}
          loading={filter.loading}
        />
      )}

      {tabs && (summary || tabTop) && (
        <div className="table-cta-tabs">
          {tabs?.list?.map((item: any, index: any) => {
            return (
              <TabButton
                key={index}
                active={tabs.activeTab === item?.key}
                onClick={() => {
                  if (tabs.activeTab !== item?.key) {
                    tabs.tabChangeHandler(item?.key);
                  }
                }}
                tooltip={item?.tooltip}
              >
                {item?.text} {item?.notification && <span className="actions-pending-symbol">•</span>}
              </TabButton>
            );
          })}
        </div>
      )}

      {customComponent && <div>{customComponent}</div>}

      <Row
        justify="space-between"
        className={`table-cta ${(tabs && !tabTop && 'table-cta-w-tabs') || 'table-cta-wo-tabs'}`}
        align="middle"
      >
        <Col>
          {title && <div>{title}</div>}
          {tabs &&
            !tabTop &&
            !summary &&
            tabs?.list?.map((item: any, index: any) => {
              return (
                <TabButton
                  key={index}
                  active={tabs.activeTab === item?.key}
                  onClick={() => {
                    if (tabs.activeTab !== item?.key) {
                      tabs.tabChangeHandler(item?.key);
                    }
                  }}
                  tooltip={item?.tooltip}
                >
                  {item?.text} {item?.notification && <span className="actions-pending-symbol">•</span>}
                </TabButton>
              );
            })}
          {summary && (
            <div className="table-count-summary">
              {summary?.totalCount != null && (
                <span>
                  Total Records: {summary?.totalCount}
                  {summary?.checklistCount != null && <> | Selected: {summary?.checklistCount}</>}
                </span>
              )}
            </div>
          )}
          {customComponentTwo && <div>{customComponentTwo}</div>}
        </Col>

        <Col>
          <Row align="middle" justify="end" gutter={15}>
            <Col>
              <Row align="middle" gutter={16}>
                {search?.key && (
                  <Col>
                    <Input
                      allowClear
                      value={searchTerm}
                      className={`search-input ${search?.size || 'large'}`}
                      bordered={true}
                      prefix={<img src={searchIcon} alt="search" />}
                      placeholder={search?.placeholder}
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                      disabled={search?.disabled}
                    />
                  </Col>
                )}

                {filter && (
                  <Col
                    onClick={() => {
                      setFilterVisible(true);
                    }}
                  >
                    <Tooltip overlay="Filter">
                      <Badge dot={filter?.notification}>
                        <div className="table-cta-small-btn" onClick={filter?.onClick}>
                          <div>
                            <img src={filterIcon} alt="filter" />
                          </div>
                        </div>
                      </Badge>
                    </Tooltip>
                  </Col>
                )}

                {sort && (
                  <Col>
                    <Tooltip overlay="Sort">
                      <Badge dot={sort?.notification}>
                        <Dropdown overlay={sortingMenu} trigger={['hover', 'click']}>
                          <div className="table-cta-small-btn" onClick={sort?.onClick}>
                            <div>
                              <img src={sortIcon} alt="Sort" height={14} width={14} />
                            </div>
                          </div>
                        </Dropdown>
                      </Badge>
                    </Tooltip>
                  </Col>
                )}
              </Row>
            </Col>

            {(cta?.icon || filter || sort || search?.key) &&
              (cta?.text || cta?.textGroup || cta?.button || cta?.buttonGroup || cta?.toggle) && (
                <span className="cta-divider"></span>
              )}

            {(cta?.text || cta?.textGroup) && (
              <>
                <Col>
                  <Row align="middle" gutter={16}>
                    {cta?.text &&
                      cta?.text?.map((item: any, index: any) => {
                        return <Col key={index}>{CTAOptions(item, textCTA)}</Col>;
                      })}
                    {cta?.textGroup &&
                      cta?.textGroup?.map((item: any, index: any) => {
                        return (
                          <Col key={index}>
                            <Dropdown overlay={groupOverlay(item?.list)} trigger={['click', 'hover']}>
                              <Space>
                                <span className={`cta ${item?.disabled ? 'cta-disabled' : ''}`} onClick={item?.onClick}>
                                  {coreCTA(item)}
                                  {dropdownArrow}
                                </span>
                              </Space>
                            </Dropdown>
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </>
            )}

            {(cta?.text || cta?.textGroup || cta?.icon) && (cta?.button || cta?.buttonGroup || cta?.toggle) && (
              <span className="cta-divider"></span>
            )}

            {(cta?.button || cta?.buttonGroup) && (
              <>
                <Col>
                  <Row align="middle" gutter={16}>
                    {cta?.button &&
                      cta?.button?.map((item: any, index: any) => {
                        return <Col key={index}>{CTAOptions(item, buttonCTA)}</Col>;
                      })}
                    {cta?.buttonGroup &&
                      cta?.buttonGroup?.map((item: any, index: any) => {
                        return (
                          <Col key={index}>
                            <Dropdown
                              disabled={item?.disabled}
                              overlay={groupOverlay(item?.list)}
                              trigger={['click', 'hover']}
                            >
                              <Button
                                size={item?.size || 'small'}
                                type={item?.type || 'primary'}
                                disabled={item?.disabled}
                                editPermission={item?.editPermission}
                                loading={item?.loading}
                                tooltip={item?.tooltip}
                              >
                                <Space>
                                  {coreCTA(item)}
                                  {!item?.disabled && dropdownArrow}
                                </Space>
                              </Button>
                            </Dropdown>
                          </Col>
                        );
                      })}
                  </Row>
                </Col>
              </>
            )}

            {cta?.toggle && (
              <>
                {(cta?.button || cta?.icon || cta?.text) && <span className="cta-divider"></span>}
                <Col>
                  <Row align="middle" gutter={16}>
                    {cta?.toggle?.map((item: any, index: any) => {
                      return (
                        <Col key={index} className={item?.disabled ? 'cta' : 'cta cta-disabled'}>
                          {item?.text && <span className="cta-title">{item?.text} : </span>}
                          {item?.option1}&nbsp;
                          <Switch
                            checked={item?.active}
                            onChange={(val) => {
                              item?.onClick(val);
                            }}
                            loading={item?.loading || false}
                            disabled={item?.disabled}
                          />
                          &nbsp;{item?.option2}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default TableCTAComponent;
