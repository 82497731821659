import { createSlice } from '@reduxjs/toolkit';
import { deleteLocalData, getLocalData, setLocalData } from '../utils/helper';

const initialState =
  getLocalData('memberData') === null
    ? {
        member_list: [],
        duplicate_list: [],
        incomplete_list: [],
        header_list: [],
        dry_run: true,
        error_sheet: {},
        endorsements_list: [],
      }
    : {
        member_list: getLocalData('memberData')?.member_list,
        duplicate_list: getLocalData('memberData')?.duplicate_list,
        incomplete_list: getLocalData('memberData')?.incomplete_list,
        header_list: getLocalData('memberData')?.header_list,
        dry_run: getLocalData('memberData')?.dry_run,
        error_sheet: getLocalData('memberData')?.error_sheet,
        endorsements_list: getLocalData('memberData')?.endorsements_list,
      };

const memberdata = createSlice({
  name: 'memberdata',
  initialState,
  reducers: {
    setMemberData: (state, action) => {
      state.member_list = action.payload?.data?.member_list;
      state.duplicate_list = action.payload?.data?.duplicate_list;
      state.incomplete_list = action.payload?.data?.incomplete_list;
      state.header_list = action.payload?.data?.header_list;
      state.dry_run = action.payload?.data?.dry_run;
      state.error_sheet = action.payload?.data?.error_sheet;
      state.endorsements_list = action.payload?.data?.endorsements_list;
      setLocalData('memberData', action.payload?.data);
    },
    updateMemberData: (state, action) => {
      switch (action.payload?.type) {
        case 1:
          state.member_list = action.payload?.data;
          break;

        case 2:
          state.duplicate_list = action.payload?.data;
          break;

        case 3:
          state.incomplete_list = action.payload?.data;
          break;

        case 4:
          state.endorsements_list = action.payload?.data;
          break;

        default:
          break;
      }
    },
    updatedry_run: (state, action) => {
      state.dry_run = action.payload.data;
    },
    reset: (state) => {
      deleteLocalData('memberData');
      state = initialState;
    },
  },
});

export const memberDataActions = memberdata.actions;

export default memberdata.reducer;
